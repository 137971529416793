<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name:"App"
};
</script>
<style lang="less">
 * {
    margin: 0;
    padding: 0;
  }
</style>
