export const home = [

    {
        path: '/',
        // redirect重定向
        redirect: '/home'
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/user-login/login'),
        
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/loans.vue')
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/AboutView.vue')
    },
    {
        path: '/loans',
        name: 'loans',

        component: () => import('@/views/loans.vue')
    },
    {
        path: '/news',
        name: 'news',

        component: () => import('@/views/news.vue')
    },
    {
        path: '/user',
        name: 'UserDetails',
        component: () => import('@/views/UserDetails')
    },
    
    {
        path: '/details',
        name: 'details',
        component: () => import('@/views/PlantDetails')
    },
    {
        path: '/information',
        name: 'FacilityInformation',
        component: () => import('@/views/FacilityInformation')
    },
    
    {
        path: '/dispose',
        name: 'dispose',
        component: () => import('@/views/dispose')
    },
    {
        path: '/loanlist',
        name: 'LoanList',
        component: () => import('@/views/LoanList')
    },
    
    {
        path: '/intendedpower',
        name: 'IntendedPower',
        component: () => import('@/views/IntendedPower')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/user-login/register')
    },
    {
        path: '/powerview',
        name: 'powerview',

        component: () => import('@/views/powerView/Powerstationoverview.vue')
    },
    {
        path: '/management',
        name: 'management',

        component: () => import('@/views/management')
    }
]