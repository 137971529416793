import axios from 'axios'
import { getCookie, setCookie } from "@/utils/cookie";
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'// progress bar style
console.log(process.env);
// 实例化
const request = axios.create({
 
  baseURL: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API : '/',
  timeout: 15000, // 10-15s
  headers: {
    // contentType: 'application/json'
  }
})
// 请求拦截器
request.interceptors.request.use((config) => {
  NProgress.start()
  console.log(getCookie("token"),"..............................");
  if (getCookie("managementtoken")) {
    config.headers['Authorization'] = getCookie("managementtoken") // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // config.headers['Content-Type'] = 'application/json'
  return config
},
error => {
  Promise.reject(error)
})

// 响应拦截器
request.interceptors.response.use(async (res) => {
  NProgress.done()
  if (res.data.code === 200) {
    return Promise.resolve(res.data)
  } else {
    return Promise.reject(res.data)
  }
})

export default request